
import { defineComponent } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "kt-activity-drawer",
  components: {},
  setup() {
    let spaces = localstorage.getSpaces();
    const router = useRouter();

    const setCurrentSpace = (item) => {
      localstorage.saveCurrentSpaces(item)
      window.location.reload() 
    }
    return {
      spaces,
      setCurrentSpace
    };
  },
});
