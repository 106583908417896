<template>
  <div
    v-if="asideSecondaryDisplay"
    class="aside-secondary d-flex flex-row-fluid"
  >
    <div class="aside-workspace my-5 p-5" id="kt_aside_wordspace">
      <div class="d-flex h-100 flex-column">
        <div
          class="flex-column-fluid hover-scroll-y"
          data-kt-scroll="true"
          data-kt-scroll-activate="true"
          data-kt-scroll-height="auto"
          data-kt-scroll-wrappers="#kt_aside_wordspace"
          data-kt-scroll-dependencies="#kt_aside_secondary_footer"
          data-kt-scroll-offset="0px"
        >
          <div class="tab-content">
            <template
              v-for="routerData in getRouterMenus()"
              :key="routerData.meta.menuId"
            >
              <div
                class="tab-pane fade"
                :class="{
                  active: hasActiveChildren(routerData.name),
                  show: hasActiveChildren(routerData.name),
                }"
                :id="routerData.meta.menuId"
                role="tabpanel"
              >
                <KTMenu :itemRoutes="routerData"></KTMenu>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button
    v-if="asideSecondaryDisplay && minimizationEnabled"
    :class="{ active: minimizedAsideSecondary }"
    class="
      btn btn-sm btn-icon btn-white btn-active-primary
      position-absolute
      translate-middle
      start-100
      end-0
      bottom-0
      shadow-sm
      d-none d-lg-flex
    "
    data-kt-toggle="true"
    data-kt-toggle-state="active"
    data-kt-toggle-target="body"
    data-kt-toggle-name="aside-minimize"
    style="margin-bottom: 1.35rem"
  >
    <span class="svg-icon svg-icon-2 rotate-180">
      <inline-svg src="media/icons/duotune/arrows/arr063.svg" />
    </span>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  minimizedAsideSecondary,
  asideSecondaryDisplay,
  minimizationEnabled,
} from "@/core/helpers/config";
import { useRouter, useRoute } from "vue-router";
import KTMenu from "./Menu.vue";


export default defineComponent({
  name: "kt-aside-secondary",
  components: {
    KTMenu,
  },
  setup() {
    const router = useRouter();
    const getRouterMenus = () => {
      let routerMenus = router.options.routes.filter((o) =>
        o.meta ? o.meta.menu : false
      );
      if (routerMenus.length === 0) {
        return [];
      }
      return routerMenus;
    };

    const route = useRoute();
    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };
    return {
      minimizedAsideSecondary,
      asideSecondaryDisplay,
      minimizationEnabled,
      getRouterMenus,
      hasActiveChildren,
    };
  },
});
</script>
