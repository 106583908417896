
import { defineComponent } from "vue";
import {
  minimizedAsideSecondary,
  asideSecondaryDisplay,
  minimizationEnabled,
} from "@/core/helpers/config";
import { useRouter, useRoute } from "vue-router";
import KTMenu from "./Menu.vue";


export default defineComponent({
  name: "kt-aside-secondary",
  components: {
    KTMenu,
  },
  setup() {
    const router = useRouter();
    const getRouterMenus = () => {
      let routerMenus = router.options.routes.filter((o) =>
        o.meta ? o.meta.menu : false
      );
      if (routerMenus.length === 0) {
        return [];
      }
      return routerMenus;
    };

    const route = useRoute();
    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };
    return {
      minimizedAsideSecondary,
      asideSecondaryDisplay,
      minimizationEnabled,
      getRouterMenus,
      hasActiveChildren,
    };
  },
});
