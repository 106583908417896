
import { defineComponent, reactive } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useRoute } from "vue-router";
import { getUserByGuid } from "@/core/services/api/user";
import jwtService from "@/core/services/JwtService";
import { getFilePath } from "@/core/utils/file";

export default defineComponent({
    name: "kt-aside-primary",
    components: {
        KTUserMenu,
    },
    setup() {
        const route = useRoute();
        const hasActiveChildren = (match) => {
            return route.path.indexOf(match) !== -1;
        };
        const userData: any = reactive({});
        const userGuid = jwtService.getToken();

        const handleGetUserByGuid = async () => {
            let request = {
                user: userGuid,
            };
            return await getUserByGuid(request);
        };

        const setUserData = async () => {
            let apiResponse = await handleGetUserByGuid();
            Object.assign(userData, {
                name: apiResponse.user.name,
                email: apiResponse.user.email,
                avatar: !apiResponse.user.theme.avatar
                    ? ""
                    : getFilePath(apiResponse.user.theme.avatar),
            });
        };

        const init = async () => {
            await setUserData();
        };
        init();

        return {
            hasActiveChildren,
            userData,
        };
    },
});
