<template>
  <!-- 次邊欄主題 -->
  <h3 class="px-10 my-lg-5">{{ item.meta.title }}</h3>

  <div
    id="#kt_header_menu"
    class="
      menu
      menu-column
      menu-fit
      menu-rounded
      menu-title-gray-600
      menu-icon-gray-400
      menu-state-primary
      menu-state-icon-primary
      menu-state-bullet-primary
      menu-arrow-gray-500
      fw-bold
      fs-5
      px-10
      my-5 my-lg-0
    "
    data-kt-menu="true"
  >
    <template v-for="(menuItem, j) in item.children" :key="j">
      <template v-if="menuItem.meta.show !== false">
        <template v-if="menuItem.meta.heading">
          <div class="menu-item">
            <router-link
              active-class="active"
              class="menu-link"
              :to="{ name: `${menuItem.name}` }"
            >
              <span
                v-if="menuItem.meta.svgIcon || menuItem.meta.fontIcon"
                class="menu-icon"
              >
                <span v-if="menuItem.meta.svgIcon" class="svg-icon svg-icon-2">
                  <inline-svg :src="menuItem.meta.svgIcon" />
                </span>
                <i v-else :class="menuItem.meta.fontIcon" class="bi fs-3"></i>
              </span>
              <span class="menu-title">{{
                translate(menuItem.meta.heading)
              }}</span>
            </router-link>
          </div>
        </template>
        <div
          v-if="menuItem.meta.sectionTitle"
          :class="{ show: hasActiveChildren(menuItem.path) }"
          class="menu-item menu-accordion"
          data-kt-menu-sub="accordion"
          data-kt-menu-trigger="click"
        >
          <span class="menu-link">
            <span
              v-if="menuItem.meta.svgIcon || menuItem.meta.fontIcon"
              class="menu-icon"
            >
              <span v-if="menuItem.meta.svgIcon" class="svg-icon svg-icon-1">
                <inline-svg :src="menuItem.meta.svgIcon" />
              </span>
              <i v-else :class="menuItem.meta.fontIcon" class="bi fs-3"></i>
            </span>
            <span class="menu-title">{{
              translate(menuItem.meta.sectionTitle)
            }}</span>
            <span class="menu-arrow"></span>
          </span>
          <div
            :class="{ show: hasActiveChildren(menuItem.path) }"
            class="menu-sub menu-sub-accordion"
          >
            <template v-for="(item2, k) in menuItem.children" :key="k">
              <div v-if="item2.meta.heading" class="menu-item">
                <router-link
                  active-class="active"
                  class="menu-link"
                  :to="{ name: `${item2.name}` }"
                >
                  <span class="menu-bullet">
                    <span class="bullet bullet-dot"></span>
                  </span>
                  <span class="menu-title">{{
                    translate(item2.meta.heading)
                  }}</span>
                </router-link>
              </div>
              <div
                v-if="item2.meta.sectionTitle"
                :class="{ show: hasActiveChildren(item2.path) }"
                class="menu-item menu-accordion"
                data-kt-menu-sub="accordion"
                data-kt-menu-trigger="click"
              >
                <span class="menu-link">
                  <span class="menu-bullet">
                    <span class="bullet bullet-dot"></span>
                  </span>
                  <span class="menu-title">{{
                    translate(item2.meta.sectionTitle)
                  }}</span>
                  <span class="menu-arrow"></span>
                </span>
                <div
                  :class="{ show: hasActiveChildren(item2.path) }"
                  class="menu-sub menu-sub-accordion"
                >
                  <template v-for="(item3, k) in item2.children" :key="k">
                    <div v-if="item3.meta.heading" class="menu-item">
                      <router-link
                        class="menu-link"
                        active-class="active"
                        :to="{ name: `${item3.name}` }"
                      >
                        <span class="menu-bullet">
                          <span class="bullet bullet-dot"></span>
                        </span>
                        <span class="menu-title">{{
                          translate(item3.meta.heading)
                        }}</span>
                      </router-link>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { asideMenuIcons } from "@/core/helpers/config";

export default defineComponent({
  props: ["itemRoutes"],
  setup(props) {
    let item = reactive(props.itemRoutes);
    const { t, te } = useI18n();
    const route = useRoute();
    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };
    return {
      hasActiveChildren,
      translate,
      asideMenuIcons,
      item,
    };
  },
});
</script>

<style lang="scss">
.aside-menu .menu .menu-sub .menu-item a a.menu-link {
  padding-left: calc(0.75rem + 25px);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  transition: none;
  outline: none !important;
}

.aside-menu .menu .menu-sub .menu-sub .menu-item a a.menu-link {
  padding-left: calc(1.5rem + 25px);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  transition: none;
  outline: none !important;
}
</style>