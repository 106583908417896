<template>
  <!--begin::Footer-->
  <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
    <!--begin::Container-->
    <div
      class="
        d-flex
        flex-column flex-md-row
        align-items-center
        justify-content-end
      "
      :class="{
        'container-fluid': footerWidthFluid,
        'container-xxl': !footerWidthFluid,
      }"
    >
      <!--begin::Copyright-->

      <!--end::Copyright-->

      <!--begin::Menu-->
                <div
            class="
              d-flex
              flex-center
              d-flex
              justify-content-end
              flex-wrap
              fs-6
              p-5
              pb-0
            "
          >
            <!--begin::Links-->
            <div class="d-flex flex-center fw-bold fs-6">
              <span class="text-muted font-weight-bold me-3"
                >© 2022 Viainno</span
              >
              <a
                href="https://www.viainno.com/_HomeGo_Privacy.php"
                class="privacyPolicy"
                >隱私權政策</a
              >
            </div>
            <!--end::Links-->
          </div>
      <!--end::Menu-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Footer-->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";

export default defineComponent({
  name: "KTFooter",
  setup() {
    return {
      footerWidthFluid,
    };
  },
});
</script>

<style scoped>
.privacyPolicy {
  color: #b5b5c3;
}

.privacyPolicy:hover {
  cursor: pointer;
  color: #249cad;
}
</style>
