import ApiService from "@/core/services/ApiService";
import { UserExtend } from "@/core/services/responsesModel/userExtend"

export function getUserByGuid(request): Promise<UserExtend> {
    return new Promise((resolve, reject) => {
        ApiService.post(`User/Query`, request).then(res => {
            resolve(res.data.user)
        }).catch(err => {
            reject(err)
        })
    })
}

// 使用者變更密碼
export function changeUserPassword(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`User/ChangePassword`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}