<template>
  <!--begin::Activities drawer-->
  <div
    id="kt_activities"
    class="bg-white"
    data-kt-drawer="true"
    data-kt-drawer-name="activities"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'300px', 'lg': '400px'}"
    data-kt-drawer-direction="start"
    data-kt-drawer-toggle="#kt_activities_toggle"
    data-kt-drawer-close="#kt_activities_close"
  >
    <div class="card shadow-none" style="width:100%">
      <!--begin::Header-->
      <div class="card-header" id="kt_activities_header" >
        <h3 class="card-title fs-2 fw-bolder text-dark">空間切換</h3>

        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-icon btn-active-light-primary me-n5"
            id="kt_activities_close"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </button>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body position-relative" id="kt_activities_body">
        <div>

          <ul class="list-group list-group-flush">
            <template v-for="(item, index) in spaces" :key="index">
              <li class="list-group-item"><button type="button" class="btn btn-link fs-4 fw-normal" @click="setCurrentSpace(item)">{{ item.space.name }}</button></li>
            </template>
          </ul>
        </div>
      </div>
      <!--end::Body-->

      <!--begin::Footer-->
      
      <!--end::Footer-->
    </div>
  </div>
  <!--end::Activities drawer-->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import localstorage from "@/core/services/LocalstorageService";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "kt-activity-drawer",
  components: {},
  setup() {
    let spaces = localstorage.getSpaces();
    const router = useRouter();

    const setCurrentSpace = (item) => {
      localstorage.saveCurrentSpaces(item)
      window.location.reload() 
    }
    return {
      spaces,
      setCurrentSpace
    };
  },
});
</script>
