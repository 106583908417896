
import { defineComponent, reactive, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { changeUserPassword } from "@/core/services/api/user";

export default defineComponent({
  setup(props,{emit}) {
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      oldPassWord: [
        {
          required: true,
          message: "請輸入舊密碼",
          trigger: "blur",
        },
      ],
      newPassWord: [
        {
          required: true,
          message: "請輸入新密碼",
          trigger: "blur",
        },
      ],
      confirmnewPassWord: [
        {
          required: true,
          message: "請輸入新密碼",
          trigger: "blur",
        },
      ],
    });
    const form: any = reactive({
      oldPassWord: "",
      newPassWord: "",
      confirmnewPassWord: "",
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          if (form.newPassWord == form.confirmnewPassWord) {
            let request = {
              password: form.oldPassWord,
              newPassword: form.newPassWord,
            };
            await changeUserPassword(request)
              .then(() => {
                Swal.fire("修改成功!", "", "success");
                emit("onChangeUserPassword")
              })
              .catch(() => {
                Swal.fire({
                  icon: "error",
                  title: "密碼錯誤",
                });
              });
          } else {
            Swal.fire({
              icon: "error",
              title: "確認密碼錯誤",
            });
          }
        }
      });
    };
    return {
      rules,
      formRef,
      form,
      submit,
    };
  },
});
