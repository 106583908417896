<template>
    <div
        class="
            aside-primary
            d-flex
            flex-column
            align-items-lg-center
            flex-row-auto
        "
    >
        <div
            class="
                aside-logo
                d-none d-lg-flex
                flex-column
                align-items-center
                flex-column-auto
                py-10
            "
            id="kt_aside_logo"
        >
            <!-- <img alt="Logo" src="media/logos/logo-demo7.svg" class="h-30px" /> -->
            <img src="icon/svg/spaceLogo.svg" alt="Logo" class="h-40px" />
        </div>

        <div
            class="
                aside-nav
                d-flex
                flex-column
                align-items-center
                flex-column-fluid
                w-100
                pt-5 pt-lg-0
            "
            id="kt_aside_nav"
        >
            <div
                class="hover-scroll-y mb-10"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-height="auto"
                data-kt-scroll-wrappers="#kt_aside_nav"
                data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                data-kt-scroll-offset="0px"
            >
                <ul class="nav flex-column">
                    <!-- dashboard -->
                    <li
                        class="nav-item mb-2"
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        data-bs-placement="right"
                        data-bs-dismiss="click"
                        title=""
                        data-bs-original-title="Projects"
                    >
                        <a
                            class="
                                nav-link
                                btn
                                btn-icon
                                btn-active-color-primary
                                btn-color-gray-400
                                btn-active-light
                            "
                            :class="{ active: hasActiveChildren('/dashboard') }"
                            data-bs-toggle="tab"
                            href="#dashboard"
                        >
                            <span class="svg-icon svg-icon-2x">
                                <inline-svg
                                    src="icon/svg/gen025.svg"
                                />
                            </span>
                        </a>
                    </li>
                    <!-- 基本管理 -->
                    <li
                        class="nav-item mb-2"
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        data-bs-placement="right"
                        data-bs-dismiss="click"
                        title="基本管理"
                        data-bs-original-title="Projects"
                    >
                        <a
                            class="
                                nav-link
                                btn
                                btn-icon
                                btn-active-color-primary
                                btn-color-gray-400
                                btn-active-light
                            "
                            :class="{
                                active: hasActiveChildren('/basicSettings'),
                            }"
                            data-bs-toggle="tab"
                            href="#basicSettings"
                        >
                            <span class="svg-icon svg-icon-2x">
                                <inline-svg
                                    src="icon/svg/Building.svg"
                                />
                            </span>
                        </a>
                    </li>
                    <!-- IoT 設定 -->
                    <li
                        class="nav-item mb-2"
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        data-bs-placement="right"
                        data-bs-dismiss="click"
                        title="IoT 設定"
                        data-bs-original-title="Projects"
                    >
                        <a
                            class="
                                nav-link
                                btn
                                btn-icon
                                btn-active-color-primary
                                btn-color-gray-400
                                btn-active-light
                            "
                            :class="{
                                active: hasActiveChildren('/IoTSettings'),
                            }"
                            data-bs-toggle="tab"
                            href="#IoTSettings"
                        >
                            <span class="svg-icon svg-icon-2x">
                                <inline-svg
                                    src="icon/svg/Git4.svg"
                                />
                            </span>
                        </a>
                    </li>
                    <!-- 成員管理 -->
                    <li
                        class="nav-item mb-2"
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        data-bs-placement="right"
                        data-bs-dismiss="click"
                        title="成員管理"
                        data-bs-original-title="Projects"
                    >
                        <a
                            class="
                                nav-link
                                btn
                                btn-icon
                                btn-active-color-primary
                                btn-color-gray-400
                                btn-active-light
                            "
                            :class="{
                                active: hasActiveChildren('/memberManagement'),
                            }"
                            data-bs-toggle="tab"
                            href="#memberManagement"
                        >
                            <span class="svg-icon svg-icon-2x">
                                <inline-svg
                                    src="icon/svg/Add-user.svg"
                                />
                            </span>
                        </a>
                    </li>
                    <!-- 例行服務 -->
                    <li
                        class="nav-item mb-2"
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        data-bs-placement="right"
                        data-bs-dismiss="click"
                        title="例行服務"
                        data-bs-original-title="Projects"
                    >
                        <a
                            class="
                                nav-link
                                btn
                                btn-icon
                                btn-active-color-primary
                                btn-color-gray-400
                                btn-active-light
                            "
                            :class="{
                                active: hasActiveChildren('/routineService'),
                            }"
                            data-bs-toggle="tab"
                            href="#routineService"
                        >
                            <span class="svg-icon svg-icon-2x">
                                <inline-svg src="icon/svg/Bullet-list.svg" />
                            </span>
                        </a>
                    </li>
                    <!-- 財務處理 -->
                    <li
                        class="nav-item mb-2"
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        data-bs-placement="right"
                        data-bs-dismiss="click"
                        title="財務處理"
                        data-bs-original-title="Projects"
                    >
                        <a
                            class="
                                nav-link
                                btn
                                btn-icon
                                btn-active-color-primary
                                btn-color-gray-400
                                btn-active-light
                            "
                            :class="{
                                active: hasActiveChildren('/financialHandle'),
                            }"
                            data-bs-toggle="tab"
                            href="#financialHandle"
                        >
                            <span class="svg-icon svg-icon-2x">
                                <inline-svg src="icon/svg/Dollar.svg" />
                            </span>
                        </a>
                    </li>
                    <!-- 報表與分析 -->
                    <li
                        class="nav-item mb-2"
                        data-bs-toggle="tooltip"
                        data-bs-trigger="hover"
                        data-bs-placement="right"
                        data-bs-dismiss="click"
                        title="報表與分析"
                        data-bs-original-title="Projects"
                    >
                        <a
                            class="
                                nav-link
                                btn
                                btn-icon
                                btn-active-color-primary
                                btn-color-gray-400
                                btn-active-light
                            "
                            :class="{
                                active: hasActiveChildren('/analysisReport'),
                            }"
                            data-bs-toggle="tab"
                            href="#analysisReport"
                        >
                            <span class="svg-icon svg-icon-2x">
                                <inline-svg src="icon/svg/Chart-bar2.svg" />
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div
            class="
                aside-footer
                d-flex
                flex-column
                align-items-center
                flex-column-auto
            "
            id="kt_aside_footer"
        >
            <!-- 空間切換 -->
            <div class="d-flex align-items-center mb-3">
                <div
                    class="
                        btn
                        btn-icon
                        btn-active-color-primary
                        btn-color-gray-400
                        btn-active-light
                    "
                    data-kt-menu-trigger="click"
                    data-kt-menu-overflow="true"
                    data-kt-menu-placement="top-start"
                    data-kt-menu-flip="top-end"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    data-bs-dismiss="click"
                    title="空間切換"
                    id="kt_activities_toggle"
                >
                    <span class="svg-icon svg-icon-2 svg-icon-lg-1">
                        <inline-svg src="icon/svg/Playlist2.svg" />
                    </span>
                </div>
            </div>

            <div
                class="d-flex align-items-center mb-10"
                id="kt_header_user_menu_toggle"
            >
                <div
                    class="cursor-pointer symbol symbol-40px"
                    data-kt-menu-trigger="click"
                    data-kt-menu-overflow="true"
                    data-kt-menu-placement="top-start"
                    data-kt-menu-flip="top-start"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    data-bs-dismiss="click"
                >
                    <img v-if="userData.avatar != ''" :src="userData.avatar" />
                    <img v-else src="icon/png/blank.png" />
                </div>
                <KTUserMenu></KTUserMenu>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useRoute } from "vue-router";
import { getUserByGuid } from "@/core/services/api/user";
import jwtService from "@/core/services/JwtService";
import { getFilePath } from "@/core/utils/file";

export default defineComponent({
    name: "kt-aside-primary",
    components: {
        KTUserMenu,
    },
    setup() {
        const route = useRoute();
        const hasActiveChildren = (match) => {
            return route.path.indexOf(match) !== -1;
        };
        const userData: any = reactive({});
        const userGuid = jwtService.getToken();

        const handleGetUserByGuid = async () => {
            let request = {
                user: userGuid,
            };
            return await getUserByGuid(request);
        };

        const setUserData = async () => {
            let apiResponse = await handleGetUserByGuid();
            Object.assign(userData, {
                name: apiResponse.user.name,
                email: apiResponse.user.email,
                avatar: !apiResponse.user.theme.avatar
                    ? ""
                    : getFilePath(apiResponse.user.theme.avatar),
            });
        };

        const init = async () => {
            await setUserData();
        };
        init();

        return {
            hasActiveChildren,
            userData,
        };
    },
});
</script>

<style scoped></style>