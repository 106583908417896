<template>
  <el-form
    @submit.prevent="submit()"
    :model="form"
    :rules="rules"
    ref="formRef"
    class="form"
  >
    <div>
      <label class="fs-6 fw-bold mb-2">
        <span class="required">舊密碼</span>
      </label>
      <el-form-item prop="oldPassWord">
        <el-input type="password" v-model="form.oldPassWord"></el-input>
      </el-form-item>
      <label class="fs-6 fw-bold mb-2">
        <span class="required">新密碼</span>
      </label>
      <el-form-item prop="newPassWord">
        <el-input type="password" v-model="form.newPassWord"></el-input>
      </el-form-item>
      <label class="fs-6 fw-bold mb-2">
        <span class="required">確認新密碼</span>
      </label>
      <el-form-item prop="confirmnewPassWord">
        <el-input type="password" v-model="form.confirmnewPassWord"></el-input>
      </el-form-item>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-success" type="submit">送出</button>
    </div>
  </el-form>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { changeUserPassword } from "@/core/services/api/user";

export default defineComponent({
  setup(props,{emit}) {
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      oldPassWord: [
        {
          required: true,
          message: "請輸入舊密碼",
          trigger: "blur",
        },
      ],
      newPassWord: [
        {
          required: true,
          message: "請輸入新密碼",
          trigger: "blur",
        },
      ],
      confirmnewPassWord: [
        {
          required: true,
          message: "請輸入新密碼",
          trigger: "blur",
        },
      ],
    });
    const form: any = reactive({
      oldPassWord: "",
      newPassWord: "",
      confirmnewPassWord: "",
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          if (form.newPassWord == form.confirmnewPassWord) {
            let request = {
              password: form.oldPassWord,
              newPassword: form.newPassWord,
            };
            await changeUserPassword(request)
              .then(() => {
                Swal.fire("修改成功!", "", "success");
                emit("onChangeUserPassword")
              })
              .catch(() => {
                Swal.fire({
                  icon: "error",
                  title: "密碼錯誤",
                });
              });
          } else {
            Swal.fire({
              icon: "error",
              title: "確認密碼錯誤",
            });
          }
        }
      });
    };
    return {
      rules,
      formRef,
      form,
      submit,
    };
  },
});
</script>

<style>
</style>