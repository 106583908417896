
import { defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { getUserByGuid } from "@/core/services/api/user";
import jwtService from "@/core/services/JwtService";
import { getFilePath } from "@/core/utils/file";
import ChangePassword from "./components/ChangePassword.vue";
import { removeModalBackdrop } from "@/core/helpers/dom";

export default defineComponent({
    name: "kt-user-menu",
    components: {
        ChangePassword,
    },
    emits: ["onChangeUserPassword"],
    setup() {
        const changePasswordDialog = ref(false);
        const userData: any = reactive({});
        const userGuid = jwtService.getToken();
        const router = useRouter();
        const store = useStore();

        const signOut = () => {
            store
                .dispatch(Actions.LOGOUT)
                .then(() => router.push({ name: "sign-in" }));
        };

        const handleGetUserByGuid = async () => {
            let request = {
                user: userGuid,
            };
            return await getUserByGuid(request);
        };

        const setUserData = async () => {
            let apiResponse = await handleGetUserByGuid();
            Object.assign(userData, {
                name: apiResponse.user.name,
                email: apiResponse.user.email,
                avatar: !apiResponse.user.theme.avatar
                    ? ""
                    : getFilePath(apiResponse.user.theme.avatar),
            });
        };

        const onChangePassword = () => {
            removeModalBackdrop();
        };

        const onChangeUserPassword = () => {
            changePasswordDialog.value = false;
        };

        const init = async () => {
            await setUserData();
        };
        init();

        return {
            signOut,
            userData,
            onChangePassword,
            changePasswordDialog,
            onChangeUserPassword,
        };
    },
});
