<template>
    <div
        class="
            menu
            menu-sub
            menu-sub-dropdown
            menu-column
            menu-rounded
            menu-gray-600
            menu-state-bg-light-primary
            fw-bold
            py-4
            fs-6
            w-275px
        "
        data-kt-menu="true"
    >
        <!-- 使用者訊息 -->
        <div class="menu-item px-3">
            <div class="menu-content d-flex align-items-center px-3">
                <div class="symbol symbol-50px me-5">
                    <img v-if="userData.avatar != ''" :src="userData.avatar" />
                    <img v-else src="icon/png/blank.png" />
                </div>
                <div class="d-flex flex-column">
                    <div class="fw-bolder d-flex align-items-center fs-5">
                        {{ userData.name }}
                        <span
                            class="
                                badge badge-light-success
                                fw-bolder
                                fs-8
                                px-2
                                py-1
                                ms-2
                            "
                            >管理員</span
                        >
                    </div>
                    <span class="fw-bold text-muted text-hover-primary fs-7">{{
                        userData.email
                    }}</span>
                </div>
            </div>
        </div>
        <!-- 分隔線 -->
        <div class="separator my-2"></div>
        <!-- 修改密碼 -->
        <div class="menu-item px-5">
            <span @click="changePasswordDialog = true" class="menu-link px-5"
                >修改密碼</span
            >
        </div>
        <!-- 意見回饋 -->
        <div class="menu-item px-5">
            <a href="mailto:service@spacesgo.com" class="menu-link px-5"
                >意見回饋</a
            >
        </div>
        <!-- 關於平台 -->
        <div class="menu-item px-5">
            <a
                href="https://www.viainno.com/_Solution_SpacesGo.php"
                target="_blank"
                class="menu-link px-5"
                >關於平台</a
            >
        </div>
        <!-- 登出 -->
        <div class="menu-item px-5">
            <a @click="signOut()" class="menu-link px-5"> 登出 </a>
        </div>
    </div>
    <!-- 修改密碼modal -->
    <el-dialog title="修改密碼" v-model="changePasswordDialog" width="30%">
        <ChangePassword
            @onChangeUserPassword="onChangeUserPassword()"
        ></ChangePassword>
    </el-dialog>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { getUserByGuid } from "@/core/services/api/user";
import jwtService from "@/core/services/JwtService";
import { getFilePath } from "@/core/utils/file";
import ChangePassword from "./components/ChangePassword.vue";
import { removeModalBackdrop } from "@/core/helpers/dom";

export default defineComponent({
    name: "kt-user-menu",
    components: {
        ChangePassword,
    },
    emits: ["onChangeUserPassword"],
    setup() {
        const changePasswordDialog = ref(false);
        const userData: any = reactive({});
        const userGuid = jwtService.getToken();
        const router = useRouter();
        const store = useStore();

        const signOut = () => {
            store
                .dispatch(Actions.LOGOUT)
                .then(() => router.push({ name: "sign-in" }));
        };

        const handleGetUserByGuid = async () => {
            let request = {
                user: userGuid,
            };
            return await getUserByGuid(request);
        };

        const setUserData = async () => {
            let apiResponse = await handleGetUserByGuid();
            Object.assign(userData, {
                name: apiResponse.user.name,
                email: apiResponse.user.email,
                avatar: !apiResponse.user.theme.avatar
                    ? ""
                    : getFilePath(apiResponse.user.theme.avatar),
            });
        };

        const onChangePassword = () => {
            removeModalBackdrop();
        };

        const onChangeUserPassword = () => {
            changePasswordDialog.value = false;
        };

        const init = async () => {
            await setUserData();
        };
        init();

        return {
            signOut,
            userData,
            onChangePassword,
            changePasswordDialog,
            onChangeUserPassword,
        };
    },
});
</script>