
import { defineComponent, computed, onMounted } from "vue";
import { StickyComponent } from "@/assets/ts/components";
import { headerWidthFluid } from "@/core/helpers/config";
import { headerFixed, headerFixedOnMobile } from "@/core/helpers/config";
import localstorage from "@/core/services/LocalstorageService";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
    breadcrumbs: Array,
  },
  components: {
  },
  setup() {
    const currentSpaceName = localstorage.getCurrentSpaces()?.space.name;
    const isHeaderSticky = computed(() => {
      if (window.innerWidth > 768) {
        return headerFixed.value;
      } else {
        return headerFixedOnMobile.value;
      }
    });

    onMounted(() => {
      StickyComponent.reInitialization();
    });

    return {
      headerWidthFluid,
      isHeaderSticky,
      currentSpaceName
    };
  },
});
