
import { defineComponent, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { asideMenuIcons } from "@/core/helpers/config";

export default defineComponent({
  props: ["itemRoutes"],
  setup(props) {
    let item = reactive(props.itemRoutes);
    const { t, te } = useI18n();
    const route = useRoute();
    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };
    return {
      hasActiveChildren,
      translate,
      asideMenuIcons,
      item,
    };
  },
});
